import React from "react";
import { useState } from "react";
import sideBarLogo from "../assets/images/logo3.jpg";
export default function Sidebar() {
  const [chatLog, setChatLog] = useState([]);

  const clearChat = () => {
    window.location.reload(false);
    setChatLog([]);
    var h11 = document.getElementById("headingdiv");
    h11.style.display = "none";
    // var h12 = document.getElementById("heading22");
    // h12.style.display = "none";
    // var h13 = document.getElementById("heading3");
    // h13.style.display = "none";
    // var h14 = document.getElementById("heading4");
    // h14.style.display = "none";
    // var h15 = document.getElementById("es1");
    // h15.style.display = "none";
    // var h16 = document.getElementById("cs1");
    // h16.style.display = "none";
    // var h17 = document.getElementById("ls1");
    // h17.style.display = "none";
  };
  function NewTab1() {
    window.open("https://discord.com/invite/openai", "_blank");
  }
  function NewTab2() {
    window.open(
      "https://help.openai.com/en/collections/3742473-chatgpt",
      "_blank"
    );
  }
  function NewTab3() {
    window.open("", "_self", "");
    window.close();
  }
  var menu = document.getElementById("menu");
  var main = document.getElementById("main");

  // show the menu
  function openMenu() {
    menu.classList.remove("-ml-[90%]");
    menu.classList.add("ml-0");
  }

  // make the menu "go away"
  function closeMenu() {
    menu.classList.remove("ml-0");
    menu.classList.add("-ml-[90%]");
  }

  return (
    <>
      <aside
        id="menu"
        class="w-[90%] -ml-[90%] h-screen flex bg-[#202123] duration-700 md:hidden lg:hidden absolute z-[99]"
      >
        <div class="w-full flex flex-col text-white px-5 py-4 space-y-4">
          <a
            onClick={closeMenu}
            href="javascript:void(0)"
            class="text-right text-4xl hover:text-red-400"
          >
            &times;
          </a>
          <div>
            <img
              src={sideBarLogo}
              alt=""
              style={{ width: "100%", marginTop: "20px" }}
            />
            {/* <h1 style={{ letterSpacing: "3px" }}>ChatCGP</h1> */}
          </div>
          <div className="side-menu-button " id="btn1" onClick={clearChat}>
            <span>+</span>
            New chat
          </div>
        </div>
      </aside>
      <div
        id="main"
        className="md:hidden lg:hidden   text-2xl font-semibol  absolute flex w-[100%] bg-[#343541] p-2 justify-between"
      >
        <div className="cursor-pointer hover:text-blue-500" onClick={openMenu}>
          &#9776;
        </div>
        <h1 className="font-normal font text-xs justify-center">New chat</h1>
        <div
          className="plus alt hover:text-blue-500 cursor-pointer"
          onClick={clearChat}
        ></div>
      </div>

      <aside className="Sidemenu sm:hidden md:block md:w-[20%]">
        <div>
          <img
            src={sideBarLogo}
            alt=""
            style={{ width: "100%", marginTop: "20px" }}
          />
          {/* <h1 style={{ letterSpacing: "3px" }}>ChatCGP</h1> */}
        </div>
        <div className="side-menu-button" id="btn1" onClick={clearChat}>
          <span>+</span>
          New chat
        </div>

        {/* <div className="clear-convo" onClick={clearChat}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={2}
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon1"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 6L5 6 21 6" />
            <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
            <path d="M10 11L10 17" />
            <path d="M14 11L14 17" />
          </svg>
          <span> Clear conversations </span>
        </div>

        <div className="light-mode">
          <svg
            className="icon2"
            stroke="currentColor"
            fill="none"
            viewBox="2 0 22 22"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
          </svg>
          <span> Dark mode</span>
        </div>

        <div className="openai-discord" onClick={NewTab1}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={2}
            viewBox="2 0 640 512"
            className="icon3"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M524.531 69.836a1.5 1.5 0 00-.764-.7A485.065 485.065 0 00404.081 32.03a1.816 1.816 0 00-1.923.91 337.461 337.461 0 00-14.9 30.6 447.848 447.848 0 00-134.426 0 309.541 309.541 0 00-15.135-30.6 1.89 1.89 0 00-1.924-.91 483.689 483.689 0 00-119.688 37.107 1.712 1.712 0 00-.788.676C39.068 183.651 18.186 294.69 28.43 404.354a2.016 2.016 0 00.765 1.375 487.666 487.666 0 00146.825 74.189 1.9 1.9 0 002.063-.676A348.2 348.2 0 00208.12 430.4a1.86 1.86 0 00-1.019-2.588 321.173 321.173 0 01-45.868-21.853 1.885 1.885 0 01-.185-3.126 251.047 251.047 0 009.109-7.137 1.819 1.819 0 011.9-.256c96.229 43.917 200.41 43.917 295.5 0a1.812 1.812 0 011.924.233 234.533 234.533 0 009.132 7.16 1.884 1.884 0 01-.162 3.126 301.407 301.407 0 01-45.89 21.83 1.875 1.875 0 00-1 2.611 391.055 391.055 0 0030.014 48.815 1.864 1.864 0 002.063.7A486.048 486.048 0 00610.7 405.729a1.882 1.882 0 00.765-1.352c12.264-126.783-20.532-236.912-86.934-334.541zM222.491 337.58c-28.972 0-52.844-26.587-52.844-59.239s23.409-59.241 52.844-59.241c29.665 0 53.306 26.82 52.843 59.239 0 32.654-23.41 59.241-52.843 59.241zm195.38 0c-28.971 0-52.843-26.587-52.843-59.239s23.409-59.241 52.843-59.241c29.667 0 53.307 26.82 52.844 59.239 0 32.654-23.177 59.241-52.844 59.241z" />
          </svg>
          <span> OpenAI Discord </span>
        </div>

        <div className="u-faq" onClick={NewTab2}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={2}
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6" />
            <path d="M15 3L21 3 21 9" />
            <path d="M10 14L21 3" />
          </svg>
          <span> Updates & FAQ </span>
        </div>

        <div className="l-out" onClick={NewTab3}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={2}
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon5"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4" />
            <path d="M16 17L21 12 16 7" />
            <path d="M21 12L9 12" />
          </svg>

          <span> Log out </span>
        </div> */}
      </aside>
    </>
  );
}
