//import logo from './logo.svg';
import "./App.css";
import "./normal.css";
import * as React from "react";

import { useState } from "react";
import Sidebar from "./Component/Sidebar";
import axios from "axios";
import { useEffect } from "react";
import TypeWriter from "typewriter-effect";
import Typewriter from "react-ts-typewriter";
import botAvatar from "../src/assets/images/bot_avatar.jpg";
import userAvatar from "../src/assets/images/user_avatar.svg";
// Calling the function for front and backend.
function App() {
  // Just in case if we want to use Specific engine from drop down list
  // useEffect(()=>{
  //   getEngines();
  // },[])

  // Setting the input
  const [input, setInput] = useState("");

  // Setting a pre draft chat log
  const [chatLog, setChatLog] = useState([]);
  const [loading, setLoading] = useState(false);

  // For setting the models.
  //const [models,setModels] = useState([]);
  //const [currentModel,setcurrentModel] = useState("def");
  // Getting engine
  //function getEngines(){
  //  fetch("http://localhost:3080/models")
  // .then(res => res.json())
  // .then(data => {
  //   console.log(data.models.data)
  // setModels(data.models.data)
  // })}
  // To clear the screen.

  function clearChat() {
    //setChatLog([]);

    var hideDiv = document.getElementById("headingdiv");
    hideDiv.style.display = "none";
    // var h11 = document.getElementById("heading");
    // h11.style.display = "none";

    // var h12 = document.getElementById("heading22");
    // h12.style.display = "none";

    // var h13 = document.getElementById("heading3");
    // h13.style.display = "none";

    // var h14 = document.getElementById("heading4");
    // h14.style.display = "none";

    // var h15 = document.getElementById("es1");
    // h15.style.display = "none";

    // var h16 = document.getElementById("cs1");
    // h16.style.display = "none";

    // var h17 = document.getElementById("ls1");
    // h17.style.display = "none";
  }

  function NewTab1() {
    window.open("https://discord.com/invite/openai", "_blank");
  }

  function NewTab2() {
    window.open(
      "https://help.openai.com/en/collections/3742473-chatgpt",
      "_blank"
    );
  }

  function NewTab3() {
    window.open("", "_self", "");
    window.close();
  }
  function showTypingIndicator() {
    let typingIndicator = document.getElementById("typing-indicator");
    typingIndicator.style.display = "block";
  }

  function hideTypingIndicator() {
    let typingIndicator = document.getElementById("typing-indicator");
    typingIndicator.style.display = "none";
  }

  // Function to fetch input and get reply from server
  async function handleSubmit(e) {
    if (input === "") {
      alert("Please type some message");
      return;
    }

    clearChat();
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      showTypingIndicator();
    }, 1000);

    // Taking input from the user
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];
    setInput("");
    setChatLog(chatLogNew);
    axios
      .request({
        method: "POST",
        body: input,
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          bot_id: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY,
          msg: input,
          session_name: "",
          forwardMessageFlag: "false",
        }),
      })
      .then((res) => {
        console.log(res.data.response);
        setChatLog([
          ...chatLogNew,
          { user: "gpt", message: `${res.data.response}` },
        ]);

        setLoading(false);
        hideTypingIndicator();
      });

    // const messages = chatLogNew.map((message) => message.message).join("\n");

    // // Sending message to the server which is index.js
    // const response = await fetch("http://localhost:3080/", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     message: messages,
    //     //  currentModel,
    //   }),
    // });

    // // Receiving the response from server and setting the reply from chatgpt engine
    // const data = await response.json();
    // setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}` }]);
    // console.log(data.message);
  }

  // Side menu of our chatgpt it's interface
  useEffect(() => {
    document.getElementById("headingdiv").style.display = "none";
    getGreetingMessage();
  }, []);

  const getGreetingMessage = () => {
    axios
      .request({
        method: "POST",
        body: input,
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_welcome_messages",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          bot_id: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY,
        }),
      })
      .then((res) => {
        console.log(res.data.user_messages);
        var greetingsStringArray = res.data.user_messages;
        let concatedGreetingsArray = greetingsStringArray.join("<br/>");
        console.log("CONCATED: ", concatedGreetingsArray);
        setChatLog([{ user: "gpt", message: `${concatedGreetingsArray}` }]);
      });
  };

  useEffect(() => {
    var objDiv = document.getElementById("chatbox");
    objDiv.scrollTop = objDiv.scrollHeight;
    var objDiv1 = document.getElementById("chat-log");
    objDiv1.scrollTop = objDiv1.scrollHeight;
  }, [chatLog]);
  return (
    <>
      <div className="App">
        <Sidebar></Sidebar>
        <section className="chatbox" id="chatbox">
          <div id="headingdiv">
            <h1 id="heading" className="heading1">
              Chat CGP
            </h1>

            <h2 id="heading22" className="heading2">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon7"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx={12} cy={12} r={5} />
                <path d="M12 1L12 3" />
                <path d="M12 21L12 23" />
                <path d="M4.22 4.22L5.64 5.64" />
                <path d="M18.36 18.36L19.78 19.78" />
                <path d="M1 12L3 12" />
                <path d="M21 12L23 12" />
                <path d="M4.22 19.78L5.64 18.36" />
                <path d="M18.36 5.64L19.78 4.22" />
              </svg>
              Examples
            </h2>

            <h2 id="heading3" className="heading3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 110 110"
                strokeWidth={1.5}
                stroke="currentColor"
                aria-hidden="true"
                className="icon8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                />
              </svg>
              Capabilities
            </h2>

            <h2 id="heading4" className="heading4">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth={1.5}
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon9"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z" />
                <path d="M12 9L12 13" />
                <path d="M12 17L12.01 17" />
              </svg>
              Limitations
            </h2>

            <div className="example" id="es1">
              <div className="l1">Example Text </div>

              <div className="l2"> Example Text </div>

              <div className="l3"> Example Text </div>
            </div>

            <div className="capa" id="cs1">
              <div className="c1">Capability Text </div>

              <div className="c2"> Capability Text </div>

              <div className="c3"> Capability Text </div>
            </div>

            <div className="limi" id="ls1">
              <div className="li1">Limitation Text </div>

              <div className="li2"> Limitation Text </div>

              <div className="li3"> Limitation Text </div>
            </div>
          </div>
          <div id="chat-log" className="chat-log sm:mt-[10%] md:mt-0">
            {chatLog.map((message, index) => (
              <div
                className={`chat-message ${message.user === "gpt" && "chatgpt"
                  }`}
              >
                <p id="demo"></p>

                <div className="chat-message-center sm:pl-[15px] md:pl-[114px] lg:pl-[0px] ">
                  <div className={`avatar`} id="value" value={message.message}>
                    {message.user === "me" && (
                      <img src={userAvatar} className="avatar_image" alt="" />
                    )}
                    {message.user === "gpt" && (
                      <img src={botAvatar} className="avatar_image" alt="" />
                    )}
                  </div>

                  <p id="demo"></p>

                  <div className="message" value={message.message} id="text">
                    {message.user === "gpt" ? (
                      <TypeWriter
                        // key={mapIndex}
                        options={{
                          delay: 30,
                          //cursorClassName: "",
                          cursorClassName: "type-writter-disabled-color",

                          //   mapIndex !== chatLogLength
                          //     ? "Typewriter__wrapper"
                          //     : "Typewriter__cursor",
                          // cursor: curserRemover(),
                          // cursorClassName:
                          //   mapIndex !== chatLogLength
                          //     ? "type-writter-disabled-color"
                          //     : "Typewriter__cursor",
                        }}
                        onInit={(typewriter) => {
                          // console.log(message);
                          typewriter.typeString(message.message).start();
                        }}
                      />
                    ) : (
                      message.message
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="chat-input-holder sm:left-0 md:left-[165px] lg:left-[165px]">
            <form onSubmit={handleSubmit}>
              <div>
                <div
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <div id="typing-indicator" className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className="chat-input-textarea sm:w-[100%] md:w-[60%]">
                    <input
                      rows="1"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      className="sm:w-[92%] md:w-[92%] mr-[100px] p-[13px] outline-none "
                      placeholder="      "
                    ></input>
                  </div>

                  <div className="icon-div sm:w-[90%] md:w-[58%]">
                    <svg
                      color="black"
                      onClick={handleSubmit}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 23 23"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className=""
                      // style={{ marginTop: "20px" }}
                      height="25px"
                      width="5em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 2L11 13" />
                      <path d="M22 2L15 22 11 13 2 9 22 2z" />
                    </svg>
                  </div>
                </div>
              </div>

              {/* <div>
                <div>
                  <input
                    rows="1"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="chat-input-textarea"
                    placeholder="      "
                  ></input>
                  <div className="chat-icon">
                    <svg
                      color="black"
                      onClick={handleSubmit}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 23 23"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className=""
                      style={{ marginTop: "20px" }}
                      height="25px"
                      width="5em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 2L11 13" />
                      <path d="M22 2L15 22 11 13 2 9 22 2z" />
                    </svg>
                  </div>
                </div>
              </div> */}
            </form>
            <p className="belowtext" style={{ marginTop: "30px" }}>
              {/* ChatCGP Jan 30 Version. Free Research Preview. Our goal is to make
            AI systems more natural and safe to interact with. Your feedback
            will help us improve. */}
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

// const ChatMessage = ({ message, cursor, mapIndex, chatLogLength }) => {
//   let messages = message.message;
//   console.log("MAP INDEX IS THIS: ", chatLogLength);
//   console.log("MAP INDEX IS THIS: ", mapIndex);
//   let checkValue = false;
//   if (mapIndex === chatLogLength) {
//     checkValue = true;
//     console.log("CONDITION SATISFIED: ", checkValue);
//   }

//   // function typeWriter(id) {
//   //   var i = 0;
//   //   var txt = "Lorem ipsum dummy text blabla.";
//   //   var speed = 50;
//   //   if (i < txt.length) {
//   //     document.getElementById("text").innerHTML += txt.charAt(i);
//   //     i++;
//   //     setTimeout(typeWriter, speed);
//   //   }
//   // }
//   // useEffect(() => {
//   //   typeWriter({});
//   // }, []);

//   return (
//     <>

//     </>
//   );
// };

export default App;
